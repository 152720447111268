/** @jsx jsx */
import { jsx } from '@emotion/core'
import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'

import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Navbar from '../components/Navbar'
import Description from '../components/Description'
import { rhythm } from '../utils/typography'

import '../styles/global.css'

function NotFoundPage({ data }) {
  const { title } = data.site.siteMetadata

  return (
    <>
      <SEO />

      <Header>
        <Navbar title={title} />

        <h1>Oops!</h1>

        <Description>Page not found (404)</Description>
      </Header>

      <main
        css={{
          padding: `${rhythm(2)} ${rhythm(1 / 2)}`,
          textAlign: 'center',
        }}
      >
        <p>This page appears to have been moved, deleted or does not exist.</p>
        <p>
          <Link to="/">Go back home</Link>
        </p>
      </main>

      <Footer {...data.site.siteMetadata} />
    </>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query NotFoundQuery {
    site {
      siteMetadata {
        title
        githubUsername
        twitterUsername
        polyworkUsername
      }
    }
  }
`
