/** @jsx jsx */
import { jsx } from '@emotion/core'
import Link from 'gatsby-link'
import PropTypes from 'prop-types'

import { rhythm } from '../utils/typography'
import LogoSvg from './vectors/LogoSvg'
import DocSearch from './DocSearch'

const Navbar = ({ title }) => {
  return (
    <nav>
      <div
        css={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
        }}
      >
        <div
          css={{
            zIndex: 4,
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            maxWidth: 846,
            height: 48,
            margin: 'auto',
            padding: 6,
            marginTop: 8,
          }}
        >
          <DocSearch
            appId={process.env.GATSBY_DOCSEARCH_APP_ID}
            apiKey={process.env.GATSBY_DOCSEARCH_API_KEY}
            indexName={process.env.GATSBY_DOCSEARCH_INDEX_NAME}
          />
        </div>
      </div>

      <div css={{ marginBottom: rhythm(1), fontWeight: 'bold' }}>
        <Link
          to="/"
          css={{
            display: 'flex',
            alignItems: 'center',
            fontSize: '1rem',
          }}
        >
          <LogoSvg
            svgId="Navbar"
            size="1.5em"
            css={{
              marginRight: rhythm(1 / 2),
            }}
          />

          {title}
        </Link>
      </div>
    </nav>
  )
}

Navbar.propTypes = {
  title: PropTypes.string.isRequired,
}

export default Navbar
