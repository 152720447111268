/** @jsx jsx */
import { jsx } from '@emotion/core'

const Description = ({ children }) => (
  <p
    css={{
      fontSize: '1.2rem',
      color: '#777',
      marginBottom: 0,
    }}
  >
    {children}
  </p>
)

export default Description
